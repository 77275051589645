import { AppSettings } from '../models/AppSettings';
import { AppSettingsResponse } from '../models/AppSettingsResponse';
import { Channel } from '../models/Channel';
import { BadRequestError } from '../models/errors/BadRequestError';
import { OrderStatus } from '../models/OrderStatus';
import { StoreCurrency } from '../models/StoreCurrency';
import RequestService from './RequestService';

const ConnectifStoreService = {
    async getSettings(signed_payload: string): Promise<AppSettingsResponse> {
        try {
            const result = await RequestService.cnFetch('/api/app/settings', {
                method: 'GET',
                bigcommerceSignature: signed_payload
            });

            return (await result.json()) as AppSettingsResponse;
        } catch (error) {
            if (error instanceof BadRequestError) {
                return {
                    success: false,
                    error: {
                        errorCode: error.errorCode
                    }
                };
            }

            return { success: false };
        }
    },

    async saveSettings(
        signed_payload: string,
        appSettings: AppSettings
    ): Promise<AppSettingsResponse> {
        try {
            const result = await RequestService.cnFetch(`/api/app/settings`, {
                method: 'POST',
                bigcommerceSignature: signed_payload,
                body: JSON.stringify(appSettings)
            });

            return (await result.json()) as AppSettingsResponse;
        } catch (error) {
            if (error instanceof BadRequestError) {
                return {
                    success: false,
                    error: {
                        errorCode: error.errorCode,
                        clientId: error.clientId,
                        currencyCode: error.currencyCode
                    }
                };
            }
            return { success: false };
        }
    },

    async getChannels(signed_payload: string): Promise<Channel[] | false> {
        try {
            const result = await RequestService.cnFetch('/api/app/channels', {
                method: 'GET',
                bigcommerceSignature: signed_payload
            });

            const response = (await result.json()) as {
                channels: Channel[];
            };
            return response.channels;
        } catch (error) {
            return false;
        }
    },

    async getStoreCurrencies(
        signed_payload: string
    ): Promise<StoreCurrency[] | false> {
        try {
            const result = await RequestService.cnFetch(
                `/api/app/store-currencies`,
                {
                    method: 'GET',
                    bigcommerceSignature: signed_payload
                }
            );

            const response = (await result.json()) as {
                availableCurrencies: StoreCurrency[];
            };
            return response.availableCurrencies;
        } catch (error) {
            return false;
        }
    },

    async getOrderStatuses(
        signed_payload: string
    ): Promise<OrderStatus[] | false> {
        try {
            const result = await RequestService.cnFetch(
                '/api/app/order-statuses',
                {
                    method: 'GET',
                    bigcommerceSignature: signed_payload
                }
            );

            const response = (await result.json()) as {
                orderStatuses: OrderStatus[];
            };
            return response.orderStatuses;
        } catch (error) {
            return false;
        }
    }
};
export default ConnectifStoreService;
